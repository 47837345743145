<template>
    <div></div>
</template>
<script type>
import { getCode_api } from "@/api/common";
import Cookies from "js-cookie";
import store from '../../store/index.js'
import * as apiCommon from "@/api/common";
import { Toast } from "vant";
export default {
  data() {
    return {};
  },
  created() {
    this.getCode();
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
    });
  },
  methods: {
    getCode() {
      
      //let redirect_uri = encodeURIComponent(location.href);
      console.log(this.$route.query)
      let id = this.$route.query.id;
      let timeDate = this.$route.query.time;
      // alert(id)
      let redirect_uri = encodeURIComponent(
        store.state.URL+"#/index/qrCode"+`?id=${id}&time=${timeDate}`
      );
      let time = new Date().getTime();
      let url = window.location.href;
      if (url.indexOf("code") == -1) {
        window.location.replace("https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx70deea58f0c566b2&redirect_uri=" +
          redirect_uri +
          `&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&t=${time}`);
      }
      console.log(redirect_uri, "url", this.queryString);
      let codeUrl = JSON.parse(this.queryString(url));
      let code = codeUrl.code;
      // id = codeUrl.id;
      
      this.getUserInfo(code,id,timeDate);
    },
    queryString(url) {
      let arr = []; //存储参数的数组
      let res = {}; //存储最终JSON结果对象
      arr = url.split("?")[1].split("&");
      for (let i = 0, len = arr.length; i < len; i++) {
        //如果有等号，则执行赋值操作
        if (arr[i].indexOf("=") != -1) {
          let str = arr[i].split("="); //str=[a,1];
          res[str[0]] = str[1];
        } else {
          //没有等号，则赋予空值
          res[arr[i]] = "";
        }
      }
      res = JSON.stringify(res); //转化为JSON字符串
      return res;
    },
    //获取用户信息
    async getUserInfo(code,id,timeDate) {
      let data = {
        code: code
      };
      getCode_api(data).then(res => {
        console.log(res, 123123123);
        if (res.code == 0) {
          Cookies.set("token", res.data.access_token);
        //   this.applyUser();
            this.$router.push(
              `../workpoints/enterprise?id=${id}&time=${timeDate}`
            );
          // locache.set('milk_seller_info',res,604800);
        } else {
          // this.applyUser()
          // this.datum[0] = locache.get('milk_seller_info')
        }
      });
    },
    async applyUser() {
      let res = await apiCommon.getApplyUser();
      console.log(res, "res");
      if (res.code == 0) {
        if (res.data.union_audit) {
          if (
            res.data.union_audit.audit_state == 1 ||
            res.data.union_audit.audit_state == 2
          ) {
            this.$router.push(
              `../lador/auditDetails?id=${res.data.union_audit.audit_id}`
            );
            return false;
          }
          
        }
        if (res.data.user.member_id != 0) {
          if(res.data.user.union_member==0){
              this.$router.push("../lador/agreement");
          }else{
            this.$router.push("../lador/auditDetails")
          }

            // this.$router.push("../lador/agreement");
          }
          if (res.data.user.member_id == 0||res.data.union_audit.audit_state == 3) {
            this.$router.push("../lador/index");
          }
      }
    }
  }
};
</script>
